import {STORE_CURRENT_REQUEST_ID, STORE_COUNTRY, STORE_NOTIFICATIONS, STORE_CONFIRMATION, 
  STORE_MANAGER_DASHBOARD_JOBS, 
  STORE_MANAGER_DASHBOARD_JOBS_MEMO, 
  SET_WORK_TYPE_FILTER,
  SET_WORK_STATUS_FILTER,STORE_HEADOFFICE_STAFF, STORE_JOB_STATUSES} from "../../constants/action-types";

export function storeCurrentRequestId(payload) {
  return {type: STORE_CURRENT_REQUEST_ID, payload};
}

export function storeCountry(payload) {
  return {type: STORE_COUNTRY, payload};
}

export function storeNotifications(payload) {
  return {type: STORE_NOTIFICATIONS, payload};
}

export function storeConfirmation(payload) {
  return {type: STORE_CONFIRMATION, payload};
}
export function storeHeadofficeStaff(payload) {
  return {type: STORE_HEADOFFICE_STAFF, payload};
}
export function storeManagerDashboardJobs(payload) {
  return {type: STORE_MANAGER_DASHBOARD_JOBS, payload};
}
export function storeManagerDashboardJobsMemo(payload) {
  return {type: STORE_MANAGER_DASHBOARD_JOBS_MEMO, payload};
}
export function setWorkTypeFilter(payload) {
  return {type: SET_WORK_TYPE_FILTER, payload};
}
export function setWorkStatusFilter(payload) {
  return {type: SET_WORK_STATUS_FILTER, payload};
}
export function storeJobStatuses(payload) {
  return {type: STORE_JOB_STATUSES, payload};
}