import React, { Fragment } from 'react';
import ReactTooltip from "react-tooltip";
interface IProps {
  date: Date,
  tooltipId: string,
}

interface IState {
  daysLeft: number,
  overdue: boolean,
  warning: boolean
}

export default class DateCountdown extends React.Component<IProps, IState> {
  state = {
    daysLeft: 0,
    overdue: false,
    warning: false,
  }
  componentDidMount() {
    this.calculateCountdown()
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.date !== prevProps.date) {
      this.calculateCountdown()
    }
  }
  calculateCountdown() {
    const now = + new Date();
    const required_by = + new Date(this.props.date);
    let overdue = now > required_by;
    const diffTime = Math.abs(required_by - now)
    const days_diff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const warning = !overdue && days_diff < 7
    if (days_diff) { // if !NAN
      console.log({ days_diff, overdue, date: new Date(required_by).toDateString() })
      this.setState({
        daysLeft: days_diff,
        warning,
        overdue
      })
    }


  }
  public render() {
    const { warning, overdue, daysLeft } = this.state;
    const { tooltipId, date } = this.props;
    const tooltip = overdue ? `${daysLeft} days overdue` : `${daysLeft} days to required date`
    const onSchedule = !warning && !overdue;
    return (
      <div
        data-for={tooltipId}
        data-tip={tooltip}
        data-iscapture="true"
        className="text-sm flex items-center"
      >
        {overdue && <span className="mr-2 fi-rr-time-delete text-red-300 pt-1"></span>}
        {warning && <span className="mr-2 fi-rr-alarm-clock text-yellow-300 pt-1"></span>}
        {onSchedule && <span className="mr-2 fi-rr-clock text-green-300 pt-1"></span>}
        {new Date(date).toDateString()}
        {onSchedule &&
          <ReactTooltip
            backgroundColor="#05c46b"
            arrowColor="#05c46b"
            place="top"
            type="dark"
            className="max-w-sm bg-red-400"
            effect="solid"
            id={this.props.tooltipId}
            multiline={false}
            delayShow={200}
          />
        }
        {warning &&
          <ReactTooltip
            backgroundColor="#ffa801"
            arrowColor="#ffa801"
            textColor="#000"
            place="top"
            type="dark"
            className="max-w-sm bg-red-400"
            effect="solid"
            id={this.props.tooltipId}
            multiline={false}
            delayShow={200}
          />
        }
        {overdue &&
          <ReactTooltip
            backgroundColor="#ff3f34"
            arrowColor="#ff3f34"
            place="top"
            type="dark"
            className="max-w-sm bg-red-400"
            effect="solid"
            id={this.props.tooltipId}
            multiline={false}
            delayShow={200}
          />
        }
      </div>
    );
  }
}
