import * as React from 'react';
import ReactCountryFlag from 'react-country-flag'
import DateCountdown from './date-countdown';
import RequestManager from './request-manager';
import { storeManagerDashboardJobs, setWorkTypeFilter, setWorkStatusFilter, storeManagerDashboardJobsMemo, storeHeadofficeStaff, storeJobStatuses } from '../../redux/actions/';
import { connect } from 'react-redux';
interface IJob {
  work_type?: string,
  brief?: string,
  createdAt?: Date,
  country?: string,
  job_status?: {
    status?: string,
  },
  required_by?: Date,
  user?: {
    username?: string,
  },
  responsible?: {
    firstname?: string,
    lastname?: string,
  },
  strapi?: string,
  model_id?: string,
  staff_notes?: string,
  internal_notes?: string
}

interface PipinelineProps {
  jobs: IJob[],

}
interface PipinelineState {
  infoModalOpen: boolean,
  activeJobInfo: IJob,
}

export default class WorkPipeline extends React.Component<PipinelineProps, PipinelineState> {
  state = {
    infoModalOpen: false,
    activeJobInfo: {}
  }
  openRecordInfo(job: IJob) {
    const { infoModalOpen } = this.state;
    const activeJobInfo = job;
    if (activeJobInfo) {
      this.setState({
        activeJobInfo: infoModalOpen ? {} : activeJobInfo,
        infoModalOpen: infoModalOpen ? false : true
      })

    }
  }
  closeRecordInfo() {
    this.setState({
      activeJobInfo: {},
      infoModalOpen: false,
    })
  }

  public render() {
    const { jobs } = this.props;
    console.log('JOBS', jobs)
    const { infoModalOpen, activeJobInfo } = this.state;
    return (
      <div>
        <h2 className="text-brand-blue text-2xl font-bold">Work Pipeline</h2>
        <div className="flex w-full items-start mt-10">
          <JobTileGrid
            status="Awaiting Approval"
            title="Awaiting Approved Jobs"
            openRecordInfo={this.openRecordInfo.bind(this)}
            closeRecordInfo={this.closeRecordInfo.bind(this)}
            className="bg-indigo-200 text-indigo-700"

          />
          <JobTileGrid
            title="Waiting Jobs"
            status='Waiting'
            openRecordInfo={this.openRecordInfo.bind(this)}
            closeRecordInfo={this.closeRecordInfo.bind(this)}
            className="bg-yellow-200 text-yellow-700"
          />
          <JobTileGrid
            status="Approved"
            title="Approved Jobs"
            openRecordInfo={this.openRecordInfo.bind(this)}
            closeRecordInfo={this.closeRecordInfo.bind(this)}
            className="bg-lime-200 text-lime-700"
          />
          <JobTileGrid
            status="Working"
            title="Working Jobs"
            openRecordInfo={this.openRecordInfo.bind(this)}
            closeRecordInfo={this.closeRecordInfo.bind(this)}
            className="bg-blue-200 text-blue-700"
          />
        </div>
        {infoModalOpen &&
          <div className="fixed top-0 right-0 w-full h-full bg-black bg-opacity-70">
            <div className="p-10 h-full">

              <div className="bg-white mx-auto max-w-3xl h-full relative rounded-xl shadow-xl overflow-hidden">
                {/* CLOSE MODAL BUTTON */}
                <button className="absolute top-0 right-0 m-10 bg-white" onClick={this.closeRecordInfo.bind(this)}>
                  <span className="fi-rr-cross-circle text-red-300 hover:text-red-600 text-2xl transition duration-300"></span>
                </button>
                {/* MODAL */}
                <div className="overflow-y-auto overflow-x-hidden h-full p-10">
                  <div>
                    <h2 className="text-3xl font-bold text-brand-blue mb-2">{activeJobInfo['work_type']}</h2>
                    <div className="mb-8">
                      {activeJobInfo['brief']}
                    </div>
                  </div>
                  <RequestManager activeJobInfo={activeJobInfo} />

                  {/* Loop keys of job Object */}
                  {Object.keys(activeJobInfo).map((key) =>
                    typeof (activeJobInfo[key]) === 'string' &&
                    <div className="mb-6" key={key}>
                      <span className="font-bold mr-4 text-brand-blue capitalize">{key}: </span>
                      <div className="text-sm text-gray-800 mt-1">{activeJobInfo[key]}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    );
  }
}
interface IJobGridProps {
  status: string,
  jobs: IJob[],
  title: string,
  className: string,
  openRecordInfo: Function,
  closeRecordInfo: Function,
}
interface IJobState {
  jobs: IJob[],
}

export class ConnectedJobTileGrid extends React.Component<IJobGridProps, IJobState> {
  constructor(props) {
    super(props);
    this.getCountryCode = this.getCountryCode.bind(this)
  }
  state = {
    jobs: [],
  }
  getCountryCode(country: string) {
    const countries = [
      { name: 'New Zealand', code: 'NZ' },
      { name: 'Australia', code: 'AU' },
      { name: 'Philippines', code: 'PH' },
      { name: 'Malaysia', code: 'MY' },
      { name: 'South Africa', code: 'ZA' },
      { name: 'Indonesia', code: 'ID' },
      { name: 'Zambia', code: 'ZM' },
      { name: 'Thailand', code: 'TH' },
      { name: 'Vietnam', code: 'VN' },
      { name: 'Hong Kong', code: 'HK' },
      { name: 'Singapore', code: 'SG' },
      { name: 'Nigeria', code: 'NG' },
    ];
    const findCode = countries.filter((x) => x.name === country)
    return findCode.length > 0 ? findCode[0].code : null

  }
  openRecordInfo(key: number) {
    const gridJobs = this.props.jobs.filter((x) => x.job_status?.status === this.props.status)
    const activeJob = gridJobs[key]
    this.props.openRecordInfo(activeJob)
  }
  closeRecordInfo() {
    this.props.closeRecordInfo()
  }
  public render() {
    const { title, className, status, jobs } = this.props;

    return (
      <div className="w-3/12 px-2">
        <div>
          <div className={`${className} p-2 text-center font-bold`}><h4>{title}</h4></div>
          {this.props.jobs.filter((x) => x.job_status?.status === status).map((job, key) =>
            <div key={key} className="p-4 border rounded-lg mb-4 text-brand-blue">
              <div className="flex w-full items-center text-lg my-4 font-bold text-xs items-center">
                <div>
                  <h3>
                    {job.work_type}
                    {job.work_type === 'Design' || job.work_type === 'Web' &&
                      <span className="capitalize text-xs font-normal"> ({job.model_id})</span>
                    }
                  </h3>
                </div>
                <span className="text-brand-pink text-sm font-medium ml-auto">
                  <ReactCountryFlag
                    countryCode={this.getCountryCode(job.country)}
                    svg
                    style={{
                      width: '1.5em',
                      height: '1.5em',
                    }}
                  />
                </span>
              </div>
              <div className="font-medium flex mb-4">
                <span className="font-bold mr-auto">Deadline:</span>
                <DateCountdown date={job.required_by} tooltipId={'required-by-' + key} />
              </div>


              <p className="text-sm mb-4">
                <strong className="block text-xs mb-2">Brief:</strong>
                {job.brief ? job.brief : 'no brief was found'}
              </p>
              <button onClick={() => this.openRecordInfo(key)}>
                Click
              </button>
            </div>
          )}
        </div>

      </div>
    )
  }
}

function mapStateToProps(state, props) {
  return {
    title: props.title,
    status: props.status,
    openRecordInfo: props.openRecordInfo,
    closeRecordInfo: props.closeRecordInfo,
    className: props.className,
    jobs: state.managerDashboardJobsMemo,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    storeManagerDashboardJobs: (jobs: IJob[]) => dispatch(storeManagerDashboardJobs(jobs)),
    storeManagerDashboardJobsMemo: (memo: IJob[]) => dispatch(storeManagerDashboardJobsMemo(memo)),
    setWorkTypeFilter: (type: string) => dispatch(setWorkTypeFilter(type)),
    setWorkStatusFilter: (status: string) => dispatch(setWorkStatusFilter(status)),
    storeHeadofficeStaff: staff => dispatch(storeHeadofficeStaff(staff)),
    storeJobStatuses: staff => dispatch(storeJobStatuses(staff)),


  }
}
export const JobTileGrid = connect(mapStateToProps, mapDispatchToProps)(ConnectedJobTileGrid);