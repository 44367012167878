import React, { Component } from 'react'
const StatusColor = ({ status }) => {
  const awaitingApproval = status === 'Awaiting Approval';
  const approved = status === 'Approved';
  const rejected = status === 'Rejected';
  const waiting = status === 'Waiting';
  const complete = status === 'Complete';
  const working = status === 'Working';
  return (
    <div className={`text-center block w-5 rounded-full text-xs h-5 mx-auto
      ${awaitingApproval && 'bg-indigo-400 border-indigo-200 border text-indigo-700'}
      ${complete && 'bg-green-400 border-green-200 border text-green-700'}
      ${rejected && 'bg-red-400 border-red-200 border text-red-700'}
      ${waiting && 'bg-yellow-400 border-yellow-200 border text-yellow-700'}
      ${working && 'bg-cyan-400 border-cyan-200 border text-cyan-800'}
      ${approved && 'bg-lime-400 border-lime-200 border text-lime-600'}
    `}>
      <span className="">{/*status*/}</span>
    </div>
  )
}

export default StatusColor;