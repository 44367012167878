import * as React from 'react';

export interface IProps {
  complete: number,
  awaitingApproval: number,
  waiting: number,
  rejected: number,
  approved: number,
  working: number,
  all: number,
  workStatusFilter: string,
  jobs?: [],
  filterByStatus: Function,
}

export default class App extends React.Component<IProps> {

  public render() {
    const { complete, awaitingApproval, waiting, rejected, all, approved, working, workStatusFilter } = this.props
    const buttonClass = 'mx-2 px-5 py-2 flex items-center rounded-lg text-center transition duration-300 stretch-self flex-auto'
    return (
      <div className="flex w-full">
        <button
          onClick={(e) => { this.props.filterByStatus('all') }}
          className={`${buttonClass} ${workStatusFilter === 'all' ? 'bg-purple-600 border-2 border-purple-600 text-white shadow-lg' : 'text-purple-600 bg-purple-100 border border-purple-200'}`}
        >
          <h4 className="text-1xl font-medium mr-4">All</h4>
          <h5 className="ml-auto font-bold">{all}</h5>
        </button>

        <button
          onClick={(e) => { this.props.filterByStatus('Awaiting Approval') }}
          className={`${buttonClass} ${workStatusFilter === 'Awaiting Approval' ? 'bg-indigo-600 border-2 border-indigo-600 text-white shadow-lg' : 'text-indigo-600 bg-indigo-100 border-2 border-indigo-200'}`}
        >
          <h4 className="text-1xl font-medium mr-4">Awaiting Approval</h4>
          <h5 className=" ml-auto font-bold"> {awaitingApproval}</h5>
        </button>
        <button
          onClick={(e) => { this.props.filterByStatus('Waiting') }}
          className={`${buttonClass} ${workStatusFilter === 'Waiting' ? 'bg-yellow-500 border-2 border-yellow-500 text-white shadow-lg' : 'text-yellow-700 bg-yellow-100 border-2 border-yellow-200'}`}
        >
          <h4 className="text-1xl font-medium mr-4">Waiting</h4>
          <h5 className=" ml-auto font-bold"> {waiting}</h5>
        </button>
        <button
          onClick={(e) => { this.props.filterByStatus('Approved') }}
          className={`${buttonClass} ${workStatusFilter === 'Approved' ? 'bg-lime-600 border-2 border-lime-600 text-white shadow-lg' : 'text-lime-600 bg-lime-100 border-2 border-lime-200'}`}
        >
          <h4 className="text-1xl font-medium mr-4">Approved</h4>
          <h5 className=" ml-auto font-bold"> {approved}</h5>
        </button>
        <button
          onClick={(e) => { this.props.filterByStatus('Working') }}
          className={`${buttonClass} ${workStatusFilter === 'Working' ? 'bg-cyan-600 border-2 border-cyan-600 text-white shadow-lg' : 'text-cyan-600 bg-cyan-100 border-2 border-cyan-200'}`}
        >
          <h4 className="text-1xl font-medium mr-4">Working</h4>
          <h5 className=" ml-auto font-bold"> {working}</h5>
        </button>
        <button
          onClick={(e) => { this.props.filterByStatus('Rejected') }}
          className={`${buttonClass} ${workStatusFilter === 'Rejected' ? 'bg-red-600 border-2 border-red-600 text-white shadow-lg' : 'text-red-600 bg-red-100 border-2 border-red-200'}`}
        >
          <h4 className="text-1xl font-medium mr-4">Rejected</h4>
          <h5 className=" ml-auto font-bold"> {rejected}</h5>
        </button>
        <button
          onClick={(e) => { this.props.filterByStatus('Complete') }}
          className={`${buttonClass} ${workStatusFilter === 'Complete' ? 'bg-green-600 border-2 border-green-600 text-white shadow-lg' : 'text-green-600 bg-green-100 border border-green-200'}`}
        >
          <h4 className="text-1xl font-medium mr-4">Complete</h4>
          <h5 className=" ml-auto font-bold"> {complete}</h5>
        </button>
      </div>
    );
  }
}
