import React, { Component } from 'react'
import Sidebar from './sidebar';
import '../../content/css/managers-dashboard.css'
import auth from '../../utils/auth';
import axios from 'axios';

const API = process.env.API;

interface Props {

}
interface IRecord {
  createdAt: Date,
  country: string,
  job_status: {
    status: string,
  },
  required_by: Date,
  user: {
    username: string,
  },
  responsible: {
    firstname: string,
    lastname: string,
  },
  strapi: string,
}
interface State {
  allJobs: IRecord[],
  loading: boolean,
}


export default class Layout extends Component<Props, State> {

  render() {
    return (
      <div className="flex items-stretch w-full min-h-screen">
        <Sidebar />
        <main className="w-full">{this.props.children}</main>
      </div>
    )
  }
}
