import React, { Component } from 'react'
import { connect } from 'react-redux'
import { storeManagerDashboardJobs, setWorkTypeFilter, setWorkStatusFilter, storeManagerDashboardJobsMemo, storeHeadofficeStaff } from '../../redux/actions/';
import StatusColor from './status-color';
import axios from 'axios';
import auth from '../../utils/auth';

interface Props {
  headofficeStaff: IStaff[],
  storeManagerDashboardJobs: Function,
  storeManagerDashboardJobsMemo: Function,
  setWorkTypeFilter: Function,
  setWorkStatusFilter: Function,
  storeHeadofficeStaff: Function,
  activeJobInfo: IRecord,
  jobStatuses: IStatus[],
  managerDashboardJobsMemo: IRecord[],
  managerDashboardJobs: IRecord[]
}


interface IRecord {
  id: string,
  model_id: string,
  staff_notes: string,
  createdAt: Date,
  country: string,
  job_status: {
    status: string,
    id: string,
  },
  required_by: Date,
  user: {
    username: string,
  },
  responsible: {
    firstname: string,
    lastname: string,
    id: string,
  },
  strapi: string,
  work_type: string,
  internal_notes: string,
}


interface IStatus {
  status: string,
  id: string,
}


interface State {
  responsible: string,
  requireSave: boolean,
  job_status: string,
  staff_notes: string,
  internal_notes: string,
  saving: boolean,
  saved: boolean,
  error: string,
  saveLabel: string,
}


interface IStaff {
  firstname: string,
  lastname: string,
  email: string,
  id: string,
}


class RequestManager extends Component<Props, State> {
  state = {
    responsible: '',
    requireSave: false,
    job_status: '',
    staff_notes: '',
    internal_notes: '',
    saving: false,
    saved: false,
    error: '',
    saveLabel: 'Save'
  }
  handleSelectStaff(e) {
    const responsible = e.target.value;
    this.setState({ responsible })
    this.initChangeForSave()

  }


  componentDidMount() {
    this.setState({
      job_status: this.props.activeJobInfo.job_status.id,
      responsible: `${this.props.activeJobInfo.responsible?.id}`,
      staff_notes: this.props.activeJobInfo?.staff_notes,
      internal_notes: this.props.activeJobInfo?.internal_notes,
    })
  }



  handleJobStatus({ target }) {
    const job_status = target.value;
    this.setState({
      job_status,
    })
    this.initChangeForSave()
  }
  initChangeForSave() {
    // prepares save button ui
    this.setState({
      saveLabel: 'Save',
      saved: false,
      saving: false,
      requireSave: true,
      error: ''
    })
  }
  saveFormUpdate() {
    const { model_id, id } = this.props.activeJobInfo;
    const { responsible, job_status, staff_notes, internal_notes } = this.state;
    const data = {
      ...this.props.activeJobInfo,
      job_status,
      responsible,
      staff_notes,
      internal_notes
    }
    const token = auth.getToken();
    if (token && model_id) {
      this.setState({
        saving: true,
        saved: false,
        error: '',
        saveLabel: 'Saving'
      })
      axios({
        url: `${process.env['API']}/${model_id}/${id}`,
        method: 'PUT',
        headers: {
          authorization: `Bearer ${token}`
        },
        data: { ...data }
      })
        .then((res) => {
          // find model and replace in data table
          const allJobs = this.props.managerDashboardJobsMemo.map((job) => {
            if (job.id === id) {
              return {
                ...res.data,
                work_type: job.work_type,
                strapi: job.strapi,
                model_id: job.model_id,
              }
            }
            else {
              return job
            }
          })
          const filteredJobs = this.props.managerDashboardJobs.map((job) => {
            if (job.id === id) {
              return {
                ...res.data,
                work_type: job.work_type,
                strapi: job.strapi,
                model_id: job.model_id,
              }
            }
            else {
              return job
            }
          })
          // do the same for filtered jobs

          this.props.storeManagerDashboardJobsMemo(allJobs)
          console.log('ALL JOBS', allJobs)
          this.props.storeManagerDashboardJobs(filteredJobs)
          this.setState({
            saved: true,
            saving: false,
            error: '',
            saveLabel: 'Saved'
          })
        })
        .catch((err) => {
          console.log(err)
          this.setState({
            saveLabel: 'Save',
            saving: false,
            saved: false,
            error: 'There was an error saving this request',
          })
        })

    }

  }
  render() {
    const { headofficeStaff, jobStatuses, activeJobInfo } = this.props;
    const { job_status, responsible, requireSave, saving, saved, saveLabel, error } = this.state;

    return (
      <div className="bg-brand-lightblue shadow bg-opacity-10 px-5 py-2 mb-10 rounded-xl">
        <label className="text-brand-blue font-bold block mb-2" htmlFor="responsibility">Resposibility</label>
        <div className="flex items-center mb-4">
          <div className="mr-2 w-8"><span className="fi-rr-user ml-1 text-lg mt-1 inline-block"></span></div>
          <select onBlur={this.handleSelectStaff.bind(this)} onChange={this.handleSelectStaff.bind(this)} name="responsibility" className="border rounded-lg block w-full p-2 text-sm" value={responsible}>
            {headofficeStaff.map((staff: IStaff, key: number) =>
              <option className="text-sm" key={key} value={staff.id}>{staff.firstname} {staff.lastname}</option>
            )}
          </select>
        </div>
        <label className="text-brand-blue font-bold block mb-2" htmlFor="status">Job Status</label>
        <div className="flex items-center mb-4">
          <div className="mr-2 w-8"><StatusColor status={activeJobInfo.job_status?.status} /></div>
          <select onBlur={this.handleJobStatus.bind(this)} onChange={this.handleJobStatus.bind(this)} name="status" className="border rounded-lg block w-full p-2 text-sm" value={job_status}>
            {jobStatuses.map((status: IStatus, key: number) =>
              <option className="text-sm" key={key} value={status.id}>{status.status}</option>
            )}
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-brand-blue font-bold mb-2" htmlFor="staff_notes">Communicate notes back to <span className="text-brand-pink">{activeJobInfo.user?.username}</span></label>
          <textarea
            name="staff_notes"
            className="border rounded-lg w-full p-2 h-64 block"
            value={this.state.staff_notes}
            onChange={({ target }) => this.setState({ staff_notes: target.value, requireSave: true })}
          />
        </div>
        <div className="mb-4">
          <label className="block text-brand-blue font-bold mb-2" htmlFor="internal_notes">Add any notes for internal staff</label>
          <textarea
            name="internal_notes"
            className="border rounded-lg w-full p-2 h-64 block"
            value={this.state.internal_notes}
            onChange={({ target }) => this.setState({ internal_notes: target.value, requireSave: true })}
          />
        </div>
        <div>
          <button
            onClick={this.saveFormUpdate.bind(this)}
            className={`${requireSave ? 'bg-brand-blue text-white' : 'bg-gray-200 text-gray-700 border-gray-300 border'}  py-2 px-8 rounded-lg mb-4 font-medium transition duration-300 flex items-center`}
          >
            {saveLabel} Request
            {saving &&
              <span className="w-5 ml-2 inline-block">
                <EllipsesLoader />
              </span>
            }
            {saved && <span className="fi-rr-check ml-2"></span>}
          </button>
          <div>
            {error &&
              <div className="px-4 rounded-lg bg-yellow-200 text-yellow-900 py-2 font-bold text-sm inline-flex items-start mb-4">
                <span className="fi-rr-exclamation mr-2 inline-block text-2xl leading-tight"></span>
                <span className="inline-block leading-relaxed">{error}</span>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}





export const EllipsesLoader = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" style={{ margin: 'auto', background: 'transparent', display: 'block' }} width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <circle cx={50} cy={50} fill="none" stroke="#fff" strokeWidth={10} r={35} strokeDasharray="164.93361431346415 56.97787143782138">
        <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1" />
      </circle>
    </svg>


  )
}





function mapStateToProps(state, props) {
  return {
    headofficeStaff: state.headofficeStaff,
    activeJobInfo: props.activeJobInfo,
    jobStatuses: state.jobStatuses,
    managerDashboardJobsMemo: state.managerDashboardJobsMemo,
    managerDashboardJobs: state.managerDashboardJobs,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    storeManagerDashboardJobs: (jobs: IRecord[]) => dispatch(storeManagerDashboardJobs(jobs)),
    storeManagerDashboardJobsMemo: (memo: IRecord[]) => dispatch(storeManagerDashboardJobsMemo(memo)),
    setWorkTypeFilter: (type: string) => dispatch(setWorkTypeFilter(type)),
    setWorkStatusFilter: (status: string) => dispatch(setWorkStatusFilter(status)),
    storeHeadofficeStaff: (staff: IStaff[]) => dispatch(storeHeadofficeStaff(staff)),


  }
}

const ConnectedStaffSelect = connect(mapStateToProps, mapDispatchToProps)(RequestManager);
export default ConnectedStaffSelect;