import React, { Component, Fragment } from "react"
import WithStaffAuthentication from "../../context/WithStaffAuthentication"
import DashboardLayout from "../../components/managers-dashboard/layout"
import StatusColor from "../../components/managers-dashboard/status-color"
import DateCountdown from "../../components/managers-dashboard/date-countdown"
import RequestManager from "../../components/managers-dashboard/request-manager"
import JobPipeline from "../../components/managers-dashboard/work-pipeline"
import auth from "../../utils/auth"
import axios from "axios"
import { connect } from "react-redux"
import {
  storeManagerDashboardJobs,
  setWorkTypeFilter,
  setWorkStatusFilter,
  storeManagerDashboardJobsMemo,
  storeHeadofficeStaff,
  storeJobStatuses,
} from "../../redux/actions/"
import { Helmet } from "react-helmet"
import ReactTooltip from "react-tooltip"
import JobStatusFilterNav from "../../components/managers-dashboard/job-status-nav"
interface State {
  loading: boolean
}

class ManagersDashboard extends Component<Props, State> {
  state = {
    loading: true,
  }

  componentDidMount() {
    const token = auth.getToken()
    axios({
      method: "GET",
      url: `${process.env["API"]}/manager-dashboard/all-jobs`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(res => {
        res.data.jobs.filter(j => {
          if (j.job_status) {
            console.log(j, "yes")
          } else {
            console.log(j, "no")
          }
        })

        const jobs = this.sortJobs(res.data.jobs)
        this.props.storeManagerDashboardJobs(jobs)
        this.props.storeManagerDashboardJobsMemo(jobs)
        this.props.storeHeadofficeStaff(res.data.headofficeStaff)
        this.props.storeJobStatuses(res.data.jobStatuses)
        this.props.setWorkTypeFilter("Dashboard")
        this.props.setWorkStatusFilter("all")
        this.setState({ loading: false })
      })
      .catch(err => console.log(err))
  }
  sortJobs(jobs: IRecord[]) {
    jobs.sort((a, b) => +new Date(b.required_by) - +new Date(a.required_by))
    return jobs
  }
  render() {
    const { loading } = this.state
    return (
      <DashboardLayout {...this.props} {...this.state}>
        <Helmet>
          <title>New Image™ Manager Dashboard</title>
          <html lang="en" />
        </Helmet>
        {loading ? <PlaceHolderTable /> : <DashboardPage />}
      </DashboardLayout>
    )
  }
}

class MainManagerDashboard extends React.Component<Props, IDashboardState> {
  constructor(props: Props) {
    super(props)
    this.filterByStatus = this.filterByStatus.bind(this)
  }

  state = {
    complete: 0,
    awaitingApproval: 0,
    waiting: 0,
    rejected: 0,
    all: 0,
    approved: 0,
    working: 0,
    jobs: [],
  }
  componentDidMount() {
    this.sortJobs()
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props !== prevProps) {
      this.sortJobs()
    }
  }

  sortJobs() {
    const { jobsMemo, workTypeFilter } = this.props
    const complete = jobsMemo.filter(
      record =>
        (record.work_type === workTypeFilter ||
          workTypeFilter === "Dashboard") &&
        record.job_status.status === "Complete"
    )
    const awaitingApproval = jobsMemo.filter(
      record =>
        (record.work_type === workTypeFilter ||
          workTypeFilter === "Dashboard") &&
        record.job_status.status === "Awaiting Approval"
    )
    const waiting = jobsMemo.filter(
      record =>
        (record.work_type === workTypeFilter ||
          workTypeFilter === "Dashboard") &&
        record.job_status.status === "Waiting"
    )
    const rejected = jobsMemo.filter(
      record =>
        (record.work_type === workTypeFilter ||
          workTypeFilter === "Dashboard") &&
        record.job_status.status === "Rejected"
    )
    const approved = jobsMemo.filter(
      record =>
        (record.work_type === workTypeFilter ||
          workTypeFilter === "Dashboard") &&
        record.job_status.status === "Approved"
    )
    const working = jobsMemo.filter(
      record =>
        (record.work_type === workTypeFilter ||
          workTypeFilter === "Dashboard") &&
        record.job_status.status === "Working"
    )
    const all = jobsMemo.filter(record => record.work_type === workTypeFilter)
    this.setState({
      complete: complete.length,
      awaitingApproval: awaitingApproval.length,
      waiting: waiting.length,
      rejected: rejected.length,
      approved: approved.length,
      working: working.length,
      all: workTypeFilter === "Dashboard" ? jobsMemo.length : all.length,
    })
  }

  filterByStatus(status: string) {
    const { jobsMemo, workTypeFilter } = this.props
    console.log({ workTypeFilter })
    if (status === "all") {
      if (workTypeFilter === "Dashboard") {
        this.props.storeManagerDashboardJobs(jobsMemo)
        this.props.setWorkStatusFilter(status)
      } else {
        const records = jobsMemo.filter(
          record =>
            record.job_status.status === status &&
            record.work_type === workTypeFilter
        )
        this.props.storeManagerDashboardJobs(records)
        this.props.setWorkStatusFilter(status)
      }
    } else {
      if (workTypeFilter === "Dashboard") {
        const records = jobsMemo.filter(
          record => record.job_status.status === status
        )
        this.props.storeManagerDashboardJobs(records)
        this.props.setWorkStatusFilter(status)
      } else {
        const records = jobsMemo.filter(
          record =>
            record.job_status.status === status &&
            record.work_type === workTypeFilter
        )
        this.props.storeManagerDashboardJobs(records)
        this.props.setWorkStatusFilter(status)
      }
    }
  }
  public render() {
    const {
      complete,
      awaitingApproval,
      waiting,
      rejected,
      all,
      approved,
      working,
    } = this.state
    const { jobs, workStatusFilter, workTypeFilter, jobsMemo } = this.props
    const jobPipelineActive = workTypeFilter === "pipeline"
    return (
      <div className="-mx-10 py-10 px-20">
        {jobPipelineActive ? (
          <JobPipeline jobs={jobsMemo} />
        ) : (
          <Fragment>
            <JobStatusFilterNav
              complete={complete}
              awaitingApproval={awaitingApproval}
              waiting={waiting}
              rejected={rejected}
              approved={approved}
              working={working}
              all={all}
              workStatusFilter={workStatusFilter}
              filterByStatus={(status: string) => this.filterByStatus(status)}
            />
            <JobTable
              jobs={jobs}
              workStatusFilter={workStatusFilter}
              workTypeFilter={workTypeFilter}
            />
          </Fragment>
        )}
      </div>
    )
  }
}
interface IJobRow {
  brief: string
  project_name: string
  createdAt: Date
  country: string
  job_status: {
    status: string
  }
  required_by: Date
  user: {
    username: string
  }
  responsible: {
    firstname: string
    lastname: string
  }
  strapi: string
}

interface ITableProps {
  jobs: IRecord[]
  workTypeFilter: string
  workStatusFilter: string
}
interface ITableState {
  infoModalOpen: boolean
  activeJobInfo: any
}

export class JobTable extends Component<ITableProps, ITableState> {
  constructor(props) {
    super(props)
    this.openRecordInfo = this.openRecordInfo.bind(this)
    this.abbreviateCountry = this.abbreviateCountry.bind(this)
  }

  state = {
    infoModalOpen: false,
    activeJobInfo: {},
  }
  openRecordInfo(key: number) {
    const { jobs } = this.props
    const { infoModalOpen } = this.state
    const activeJobInfo = jobs[key]
    if (activeJobInfo) {
      this.setState({
        activeJobInfo: infoModalOpen ? {} : activeJobInfo,
        infoModalOpen: infoModalOpen ? false : true,
      })
    }
  }
  abbreviateCountry(country: string) {
    switch (country?.toLowerCase()) {
      case "australia":
        return "AU"
      case "new zealand":
        return "NZ"
      case "south africa":
        return "SA"
      case "indonesia":
        return "ID"
      case "thailand":
        return "TH"
      case "ghana":
        return "GH"
      case "malaysia":
        return "MY"
      case "philippines":
        return "PH"
      case "botswana":
        return "BT"
      case "singapore":
        return "SG"
      case "uganda":
        return "UG"
      case "nigeria":
        return "NG"
      case "taiwan":
        return "TWN"
      case "vietnam":
        return "VTN"
      default:
        return ""
    }
  }
  closeRecordInfo() {
    this.setState({
      activeJobInfo: {},
      infoModalOpen: false,
    })
  }
  render() {
    const { jobs } = this.props
    const { activeJobInfo, infoModalOpen } = this.state
    return (
      <div className="m-5">
        {/* INFO MODAL STARTS */}
        {infoModalOpen && (
          <div className="fixed top-0 right-0 w-full h-full bg-black bg-opacity-70">
            <div className="p-10 h-full">
              <div className="bg-white mx-auto max-w-3xl h-full relative rounded-xl shadow-xl overflow-hidden">
                {/* CLOSE MODAL BUTTON */}
                <button
                  className="absolute top-0 right-0 m-10 bg-white"
                  onClick={this.closeRecordInfo.bind(this)}
                >
                  <span className="fi-rr-cross-circle text-red-300 hover:text-red-600 text-2xl transition duration-300"></span>
                </button>
                {/* MODAL */}
                <div className="overflow-y-auto overflow-x-hidden h-full p-10">
                  <div>
                    <h2 className="text-3xl font-bold text-brand-blue mb-2">
                      {activeJobInfo["work_type"]}
                    </h2>
                    <div className="mb-8">{activeJobInfo["brief"]}</div>
                  </div>
                  <RequestManager activeJobInfo={activeJobInfo} />

                  {/* Loop keys of job Object */}
                  {Object.keys(activeJobInfo).map(
                    key =>
                      typeof activeJobInfo[key] === "string" && (
                        <div className="mb-6" key={key}>
                          <span className="font-bold mr-4 text-brand-blue capitalize">
                            {key}:{" "}
                          </span>
                          <div className="text-sm text-gray-800 mt-1">
                            {activeJobInfo[key]}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* TABLE */}
        {jobs.length > 0 ? (
          <div className="border border-gray-100 rounded-lg overflow-hidden">
            <table className="w-full bg-white mt-4">
              <tbody>
                {/* TABLE HEADER */}
                <tr className="bg-gray-100 text-gray-800">
                  <th className="px-2 text-sm font-bold py-5 text-center">
                    Status
                  </th>
                  <th className="px-2 text-sm font-bold py-5 text-left">
                    Work
                  </th>
                  <th className="px-2 text-sm font-bold py-5 text-left">
                    Project Name
                  </th>
                  <th className="px-2 text-sm font-bold py-5 text-left">
                    Brief
                  </th>
                  <th className="px-2 text-sm font-bold py-5 text-left">
                    Responsible
                  </th>
                  <th className="px-2 text-sm font-bold py-5 text-left">
                    Country
                  </th>
                  <th className="px-2 text-sm font-bold py-5 text-left">
                    Required By
                  </th>
                  <th className="px-2 text-sm font-bold py-5 text-center">
                    Open
                  </th>
                  <th className="px-2 text-sm font-bold py-5 text-center">
                    Manage
                  </th>
                </tr>

                {/* TABLE ROWS */}
                {jobs.map((record: IRecord, key: number) => (
                  <tr key={key}>
                    <td
                      className="px-2 py-3 text-sm border-b border-gray-100"
                      data-for={`status-${key}`}
                      data-tip={record.job_status?.status}
                      data-iscapture="true"
                    >
                      <StatusColor status={record.job_status?.status} />
                      <ReactTooltip
                        place="top"
                        type="dark"
                        effect="solid"
                        id={`status-${key}`}
                        multiline={false}
                        delayShow={200}
                      />
                    </td>

                    <td className="px-2 py-3 text-sm border-b border-gray-100 font-medium text-brand-blue">
                      {record.work_type}
                    </td>

                    <td className="px-2 py-3 text-sm border-b border-gray-100">
                      {record?.project_name}
                    </td>
                    <td
                      className="px-2 py-3 text-sm border-b border-gray-100 overflow-hidden"
                      data-for={`brief-${key}`}
                      data-tip={record.brief}
                      data-iscapture="true"
                    >
                      <span className="truncate block w-64 text-xs">
                        {record.brief}
                      </span>
                      <ReactTooltip
                        backgroundColor="#3c40c6"
                        arrowColor="#3c40c6"
                        place="right"
                        type="dark"
                        className="max-w-sm bg-red-400"
                        effect="solid"
                        id={`brief-${key}`}
                        multiline={false}
                        delayShow={200}
                      />
                    </td>

                    <td className="px-2 py-3 text-sm border-b border-gray-100">
                      {record.responsible?.firstname}&ensp;
                      {record.responsible?.lastname}
                    </td>

                    <td className="px-2 py-3 text-sm border-b border-gray-100">
                      {this.abbreviateCountry(record.country)}
                    </td>
                    <td className="px-2 py-3 text-sm border-b border-gray-100">
                      {/* add calculations to work out how much time is left from todays date */}
                      <DateCountdown
                        date={record.required_by}
                        tooltipId={`required-by-${key}`}
                      />
                    </td>

                    {/* LINK TO STRAPI */}
                    <td className="px-2 py-3 text-sm border-b border-gray-100">
                      <a
                        target="0_blank"
                        aria-label="open-in-strapi"
                        href={`${process.env["API"]}${record.strapi}`}
                        data-for={`strapi-${key}`}
                        data-tip={"Open on admin site"}
                        data-iscapture="true"
                        className="text-blue-700 block text-center"
                      >
                        <span className="fi-rr-link"></span>
                      </a>
                      <ReactTooltip
                        place="right"
                        type="dark"
                        className="max-w-sm bg-red-400"
                        effect="solid"
                        id={`strapi-${key}`}
                        multiline={false}
                        delayShow={200}
                      />
                    </td>

                    {/* REQUEST MANAGER MODAL CONTROL */}
                    <td className="px-2 py-3 text-sm border-b border-gray-100">
                      <button
                        className="text-blue-700 text-center w-full mx-auto inline-block"
                        onClick={() => this.openRecordInfo(key)}
                      >
                        <span className="fi-rr-info"></span>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <h3 className="text-2xl font-medium mt-10 text-brand-blue text-opacity-40">
              No records found
            </h3>
          </div>
        )}
      </div>
    )
  }
}

const PlaceHolderTable = () => {
  return (
    <div className="h-full self-stretch flex items-center justify-center w-full">
      <div className="flex w-full items-center justify-center">
        <div className="w-20">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            style={{
              margin: "auto",
              background: "transparent",
              display: "block",
            }}
            width="60px"
            height="60px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx={50}
              cy={50}
              r={30}
              stroke="#e5e5e5"
              strokeWidth={10}
              fill="none"
            />
            <circle
              cx={50}
              cy={50}
              r={30}
              stroke="rgba(59, 130, 246, 1)"
              strokeWidth={8}
              strokeLinecap="round"
              fill="none"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;180 50 50;720 50 50"
                keyTimes="0;0.5;1"
              />
              <animate
                attributeName="stroke-dasharray"
                repeatCount="indefinite"
                dur="1s"
                values="18.84955592153876 169.64600329384882;94.2477796076938 94.24777960769377;18.84955592153876 169.64600329384882"
                keyTimes="0;0.5;1"
              />
            </circle>
          </svg>
        </div>
        <div className="text-gray-600 font-medium text-2xl">Loading</div>
      </div>
    </div>
  )
}

function mapStateToProps(state, props) {
  return {
    jobs: state.managerDashboardJobs,
    jobsMemo: state.managerDashboardJobsMemo,
    workTypeFilter: state.workTypeFilter,
    workStatusFilter: state.workStatusFilter,
    ...props,
  }
}

function mapDispatchToProps(dispatch) {
  return {
    storeManagerDashboardJobs: (jobs: IRecord[]) =>
      dispatch(storeManagerDashboardJobs(jobs)),
    storeManagerDashboardJobsMemo: (memo: IRecord[]) =>
      dispatch(storeManagerDashboardJobsMemo(memo)),
    setWorkTypeFilter: (type: string) => dispatch(setWorkTypeFilter(type)),
    setWorkStatusFilter: (status: string) =>
      dispatch(setWorkStatusFilter(status)),
    storeHeadofficeStaff: staff => dispatch(storeHeadofficeStaff(staff)),
    storeJobStatuses: staff => dispatch(storeJobStatuses(staff)),
  }
}
export const ConnectedDashboard = connect(
  mapStateToProps,
  mapDispatchToProps
)(ManagersDashboard)
export const DashboardPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainManagerDashboard)

class DashboardPageWrapper extends Component {
  render() {
    return (
      <div>
        <ConnectedDashboard />
      </div>
    )
  }
}

export interface IDashboardState {
  complete: number
  awaitingApproval: number
  waiting: number
  rejected: number
  approved: number
  working: number
  all: number
  jobs: IRecord[]
}

interface IRecord {
  work_type: string
  project_name?: string
  brief: string
  createdAt: Date
  country: string
  job_status: {
    status: string
  }
  required_by: Date
  user: {
    username: string
  }
  responsible: {
    firstname: string
    lastname: string
  }
  strapi: string
  model_id: string
}

interface Props {
  jobs: IRecord[]
  jobsMemo: IRecord[]
  storeManagerDashboardJobsMemo: Function
  storeManagerDashboardJobs: Function
  setWorkTypeFilter: Function
  setWorkStatusFilter: Function
  storeHeadofficeStaff: Function
  storeJobStatuses: Function
  workTypeFilter: string
  workStatusFilter: string
}
export default WithStaffAuthentication(DashboardPageWrapper)
