import React, { Component } from 'react'
import { navigate } from 'gatsby';
import AuthUserContext from './AuthUserContext.js';
import auth from '../utils/auth';

interface Props {

}
interface State {
  authUser: any,
  loading: boolean,
}
const withAuthentication = (Component) =>
  class WithStaffAuthentication extends Component<Props, State> {
    state = {
      authUser: null,
      loading: true,
    }
    componentDidMount() {
      const role = auth.getUserInfo()?.role?.type || null;
      auth.getToken() !== null && role === 'staff' ? (
        this.setState({
          loading: false,
          authUser: auth.getToken(),
        })
      ) : (
        navigate('/not-authorized')
      )
    }

    render() {
      const { authUser } = this.state;
      return (
        <AuthUserContext.Provider value={authUser}>
          {authUser &&
            <Component {...this.props} />
          }
        </AuthUserContext.Provider>
      )
    }
  }
export default withAuthentication
