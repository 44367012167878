import React, { Component } from 'react';
import { connect } from 'react-redux';
import { storeManagerDashboardJobs, setWorkTypeFilter, setWorkStatusFilter } from '../../redux/actions/';
import { Link, navigate } from 'gatsby';
import Icon from '../../content/images/icon.png';
import auth from '../../utils/auth';

interface IMenuItem {
    work_type: string,
    label: string,
    icon: string,
    active: boolean,
    count?: number,
}


interface IRecord {
    createdAt: Date,
    country: string,
    job_status: {
        status: string,
    },
    required_by: Date,
    user: {
        username: string,
    },
    responsible: {
        firstname: string,
        lastname: string,
    },
    strapi: string,
    work_type: string,
}


interface Props {
    path?: string,
    storeManagerDashboardJobs: Function,
    setWorkTypeFilter: Function,
    setWorkStatusFilter: Function,
    jobs: IRecord[],
    jobsMemo: IRecord[],
    workTypeFilter: string,
    workStatusFilter: string,
}


interface State {
    menu: IMenuItem[]
    userInfo: UserInfo
}
interface UserInfo {
    username: string,
    email: string,
}
function mapStateToProps(state) {
    return {
        jobs: state.managerDashboardJobs,
        jobsMemo: state.managerDashboardJobsMemo,
        workTypeFilter: state.workTypeFilter,
        workStatusFilter: state.workStatusFilter,

    };
}

function mapDispatchToProps(dispatch) {
    return {
        storeManagerDashboardJobs: managerDashboardJobs => dispatch(storeManagerDashboardJobs(managerDashboardJobs)),
        setWorkTypeFilter: type => dispatch(setWorkTypeFilter(type)),
        setWorkStatusFilter: status => dispatch(setWorkStatusFilter(status)),
    }
}

class Sidebar extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.filterByType = this.filterByType.bind(this)
        this.state = {
            userInfo: {
                username: '',
                email: '',
            },
            menu: [{
                icon: 'fi-rr-briefcase',
                label: 'Dashboard',
                work_type: 'Dashboard',
                active: false,
                count: null,
            },
            {
                icon: 'fi-rr-video-camera',
                label: 'Videos',
                work_type: 'Videos',
                active: false,
                count: 0
            },
            {
                icon: 'fi-rr-browser',
                label: 'Web',
                work_type: 'Web',
                active: false,
                count: 0,
            },
            {
                icon: 'fi-rr-vector',
                label: 'Design',
                work_type: 'Design',
                active: false,
                count: 0,
            },
            {
                icon: 'fi-rr-document-signed',
                label: 'Documents',
                work_type: 'Documents',
                active: false,
                count: 0,
            },
            {
                icon: 'fi-rr-share',
                label: 'Social Media',
                work_type: 'Social Media',
                active: false,
                count: 0
            },
            {
                icon: 'fi-rr-label',
                label: 'Product Promotions',
                work_type: 'Product Promotions',
                active: false,
                count: 0,
            },
            ]
        }
    }


    componentDidMount() {
        const userInfo = auth.getUserInfo()
        this.setState({ userInfo })
        this.sortMenu();
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props !== prevProps) {
            this.sortMenu()
        }
    }

    sortMenu() {
        const { jobsMemo, workTypeFilter } = this.props;
        let { menu } = this.state;
        jobsMemo.forEach((job) => { if (!job.job_status) console.log(job) })
        menu.map((item) => {
            item.count = jobsMemo.filter((job) => job.job_status?.status === 'Awaiting Approval' && job.work_type === item.label).length;
            return item
        })
        this.setState({ menu: menu })
    }
    filterByType(type: string) {
        const { jobsMemo } = this.props;
        if (type === 'Dashboard') {
            this.props.setWorkTypeFilter(type)
            this.props.storeManagerDashboardJobs(jobsMemo)
        } else {
            const filtered = jobsMemo.filter((job) => job.work_type === type)
            this.props.setWorkTypeFilter(type)
            this.props.setWorkStatusFilter('all')
            this.props.storeManagerDashboardJobs(filtered)
        }


    }
    signOut() {
        auth.clearUserInfo();
        auth.clearToken();
        navigate('/auth/');
    }
    render() {
        const { workTypeFilter } = this.props;
        const { menu, userInfo } = this.state;
        return (
            <section className="bg-brand-blue min-h-screen w-full lg:max-w-sm xl:max-w-md text-white block py-10 px-5">
                <div style={{ position: 'sticky', top: '20px' }}>
                    <div className="flex items-center">
                        <div className="w-10 mr-1"><img src={Icon} alt={'logo'} /></div>
                        <h2 className="text-2xl font-medium">Manager Dashboard</h2>
                    </div>
                    <nav>
                        <ul className="my-10">
                            {menu.map((item, key) =>
                                <li key={key} className={`my-2 ${item.label === workTypeFilter && 'text-brand-pink'}`}>
                                    <button className="flex items-center w-full text-left" onClick={() => this.filterByType(item.label)}>
                                        <span className={`text-3xl block mr-4 font-normal ${item.icon}`}></span>
                                        <span className="uppercase tracking-wider font-medium">{item.label}</span>
                                        {item.count !== null && item.count !== 0 &&
                                            <span className="ml-auto px-2 py-1 rounded-xl font-bold bg-brand-pink text-white text-xs w-10 text-center">{item.count}</span>
                                        }
                                    </button>
                                </li>
                            )}
                            <li>
                                <button
                                    onClick={() => this.filterByType('pipeline')}
                                    className={workTypeFilter === 'pipeline' ? 'text-brand-pink flex mt-10 items-center' : 'text-white flex mt-10 items-center'}
                                >
                                    <span className="mr-4 block text-3xl fi-rr-inbox"></span>
                                    <span className="uppercase tracking-wider font-medium mt-1">Work Pipeline</span>

                                </button>
                            </li>
                            <li className="mt-10 mb-2">
                                <Link className="uppercase tracking-wider font-medium flex items-center" to="/">
                                    <span className={`text-3xl block mr-4 font-normal fi-rr-file-add`}></span>
                                    <span className="uppercase tracking-wider font-medium">Create a Request</span>
                                </Link>
                            </li>
                            <li>
                                <a className="uppercase tracking-wider font-medium flex items-center" href={`${process.env['API']}/admin`}>
                                    <span className={`text-3xl block mr-4 font-normal fi-rr-glasses`}></span>
                                    <span>Backend Management</span>
                                </a>
                            </li>

                            <li className="font-medium flex items-center mt-10 text-brand-pink w-full">
                                <span className={`text-3xl block mr-4 font-normal fi-rr-user`}></span>
                                <span className="font-medium text-sm mb-2"><strong>{userInfo.username}</strong><br /><span className="text-xs">{userInfo.email}</span></span>
                                <button className="ml-auto p-1 text-brand-pink hover:text-white inline-block" onClick={this.signOut.bind(this)}>
                                    <span className="fi-rr-sign-out text-3xl transition duration-300 block mr-1"></span>
                                </button>
                            </li>
                        </ul>
                    </nav>
                </div>
            </section>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);